/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import "~firebaseui/dist/firebaseui.css";
@import "~@angular/material/theming";

@include mat-core();

$primary: $theme-primary-palette;
$accent: $theme-accent-palette;
$warn: $theme-warn-palette;

$theme: mat-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
    ));

@include angular-material-theme($theme);

.mat-icon-text {
  display: flex;
  align-items: center;
  padding: 10px !important;
  margin: auto;
}

.product-listing-viewport {
  width: 100%;
  min-height: calc(100vh - 250px) !important;
}

.primary-text {
  color: mat-color($primary) !important;
}

.primary-bgcolor {
  background-color: mat-color($primary) !important;
  color: white !important;
}

.bg-primary {
  background-color: mat-color($primary) !important;
}

.bg-accent {
  background-color: mat-color($accent) !important;
}

.bg-warn {
  background-color: mat-color($warn) !important;
}

.bg-primary-light {
  background-color: mat-color($primary, 100) !important;
}

.bg-accent-light {
  background-color: mat-color($accent, 100) !important;
}

.bg-warn-light {
  background-color: mat-color($warn, 100) !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.max-height-100 {
  max-height: 100px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.max-height-150 {
  max-height: 150px !important;
}

.max-height-200 {
  max-height: 200px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.max-height-300 {
  max-height: 300px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.mat-drawer {
  background-color: white !important;
}

.mat-expansion-panel-header {
  background-color: whitesmoke !important;
}

.mat-expansion-panel-body {
  background-color: whitesmoke !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: mat-color($accent);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: mat-color($accent);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  flex-grow: 0;
}

.app-content {
  min-height: calc(var(--vh, 1vh) * 100 - 116px) !important;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.app-artwork-content {
  min-height: calc(var(--vh, 1vh) * 100 - 168px) !important;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.footer {
  flex-shrink: 0;
  flex-grow: 0;
}

.mat-icon-btn-text {
  display: flex;
  align-items: center;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100% !important;
}

.mat-sidenav .mat-icon {
  color: mat-color($primary) !important;
}

.row {
  margin: 0px !important;
  padding: 0px !important;
}

img:hover {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.row>* {
  margin: 0px !important;
  padding: 0px !important;
}

.mat-toolbar {
  height: 56px !important;
}

.mat-expansion-panel-body {
  padding: 3px !important;
}

.mat-radio-label {
  padding-right: 20px !important;
}

.artnavbar {
  background-color: mat-color($accent) !important;
}

.submenutoolbar {
  background-color: mat-color($primary, 100) !important;
}